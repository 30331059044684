<template>
  <div flex="main:justify">
    <div class="img-item-wrap img-item-wrap-big" @click="$router.pushToTab(data.value.imgs[i-1].value.link.url)" v-for="i in 2" :key="i">
      <img  :src="$img(data.value.imgs[i-1].value.url)">
    </div>
    <div flex="dir:top main:justify" class="img-item-group">
      <div class="img-item-wrap" @click="$router.pushToTab(data.value.imgs[i+1].value.link.url)" v-for="i in 2" :key="i">
        <img :src="$img(data.value.imgs[i+1].value.url)">
      </div>
    </div>
    <div flex="dir:top main:justify" class="img-item-group">
      <div class="img-item-wrap" @click="$router.pushToTab(data.value.imgs[i+3].value.link.url)" v-for="i in 2" :key="i">
        <img :src="$img(data.value.imgs[i+3].value.url)">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "floor-style-5",
  props: {
    data: {
      type: Object
    }
  },
}
</script>

<style scoped>
.img-item-wrap.img-item-wrap-big, img-item-group{width: 292px; height: 372px;}
.img-item-wrap{width: 292px; height: 178px; overflow: hidden;}
.img-item-wrap img{width: 100%;}
</style>
