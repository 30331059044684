<template>
  <div flex="box:last" style="width:100%;">
    <div flex="main:justify dir:top">
      <div>
        <el-carousel height="430px" arrow="hover" v-loading="loadingAd">
          <el-carousel-item v-for="item in adList" :key="item.adv_id">
            <el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)"/>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div flex="box:mean" class="bottom-items">
        <div class="bottom-item-wrap" v-for="(img, i) in data.value.bottomImgs" :key="i" @click="$router.pushToTab(img.value.link.url)">
          <img :src="$img(img.value.url)">
        </div>
      </div>
    </div>
    <div flex="dir:top box:mean">
      <div class="right-item-wrap"   v-for="(img, i) in data.value.rightImgs" :key="i" @click="$router.pushToTab(img.value.link.url)">
        <img :src="$img(img.value.url)">
      </div>
    </div>
  </div>

</template>

<script>
import {adList} from "@/api/website";

export default {
  name: "floor-style-4",
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      adList: [],
      loadingAd: true
    }
  },
  created() {
    this.getAdList();
  },
  methods: {
    getAdList() {
      adList({
        keyword: "NS_PC_INDEX"
      })
          .then(res => {
            console.log(res,'getAdList')
            this.adList = res.data.adv_list
            for (let i = 0; i < this.adList.length; i++) {
              if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
            }
            this.loadingAd = false
          })
          .catch(err => {
            this.loadingAd = false
          })
    },
  }
}
</script>

<style scoped>
.bottom-item-wrap{
  height: 160px;
  cursor: pointer;
  overflow: hidden;
}
.right-item-wrap{
  width: 390px;
  height: 200px;
  cursor: pointer;
  overflow: hidden;
}

.bottom-item-wrap img,
.right-item-wrap img{
  width: 100%;
}
</style>
