<template>
  <div>
    <!-- Slider-Category Section -->

    <div class="content" style="margin-top:-66px">
      <!-- 楼层区 -->
      <div class="content-div">
        <div class="floor">
          <div v-for="(item, index) in floorList" :key="index" class="floor_item">
            <floor-style-1 v-if="item.block_name == 'floor-style-1'" :data="item"/>
            <floor-style-2 v-else-if="item.block_name == 'floor-style-2'" :data="item"/>
            <floor-style-3 v-else-if="item.block_name == 'floor-style-3'" :data="item"/>
            <floor-style-4 v-else-if="item.block_name == 'floor-style-4'" :data="item"/>
            <floor-style-5 v-else-if="item.block_name == 'floor-style-5'" :data="item"/>
            <!-- 领券中心 -->
            <div class="content-div" v-if="addonIsExit.coupon == 1 && couponList.length > 0 && item.block_name == 'floor-style-system' && item.value.name == 'coupon_center'">
              <div class="coupon">
                <div class="coupon-title">
                  <p class="coupon-font">Voucher Center</p>
                  <p class="coupon-more" @click="$router.push('/coupon')">
                    <span>More</span>
                    <i class="iconfont iconarrow-right"></i>
                  </p>
                </div>
                <ul class="coupon-list">
                  <li v-for="(item, index) in couponList" :key="index">
                    <p class="coupon-price ns-text-color" v-if="item.type == 'reward'"
                       :class="{ disabled: item.useState == 2 }">
                      ₵
                      <span>{{parseInt(item.money) }}</span>
                    </p>
                    <p class="coupon-price ns-text-color" v-else-if="item.type == 'discount'"
                       :class="{ disabled: item.useState == 2 }">

                      <span>{{ parseFloat(item.discount) }}</span>/10
                    </p>
                    <p class="coupon-term" v-if="item.at_least > 0" :class="{ disabled: item.useState == 2 }">need
                      {{ item.at_least }}</p>
                    <p class="coupon-term" v-else :class="{ disabled: item.useState == 2 }">need {{ item.at_least }}</p>
                    <p class="coupon-receive ns-text-color" @click="couponTap(item, index)">
                      <span v-if="item.useState == 0">Get Now</span>
                      <span v-else>Use</span>
                      <i class="iconfont iconarrow-right"></i>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <!-- 限时秒杀 -->
            <div class="content-div" v-if="addonIsExit.seckill == 1 && listData.length > 0 && item.block_name == 'floor-style-system' && item.value.name == 'seckill'">
              <div class="seckill-wrap">
                <div class="seckill-time">
                  <div class="seckill-time-left">
                    <i class="iconfont iconmiaosha1 ns-text-color"></i>
                    <span class="seckill-time-title ns-text-color">Flash sale</span>
                    <span>{{ seckillText }}</span>
                    <count-down
                        class="count-down"
                        v-on:start_callback="countDownS_cb()"
                        v-on:end_callback="countDownE_cb()"
                        :currentTime="seckillTimeMachine.currentTime"
                        :startTime="seckillTimeMachine.startTime"
                        :endTime="seckillTimeMachine.endTime"
                        :dayTxt="'：'"
                        :hourTxt="'：'"
                        :minutesTxt="'：'"
                        :secondsTxt="''"
                    ></count-down>
                  </div>
                  <div class="seckill-time-right" @click="$router.push('/promotion/seckill')">
                    <span>More Goods</span>
                    <i class="iconfont iconarrow-right"></i>
                  </div>
                </div>
                <div class="seckill-content">
                  <vue-seamless-scroll :data="listData" :class-option="optionLeft" class="seamless-warp2">
                    <ul class="item" :style="{ width: 250 * listData.length + 'px' }">
                      <li v-for="(item, index) in listData" :key="index">
                        <div class="seckill-goods" @click="$router.pushToTab('/promotion/seckill-' + item.id)">
                          <div class="seckill-goods-img"><img :src="$img(item.goods_image, { size: 'mid' })"
                                                              @error="imageError(index)"/></div>
                          <p>{{ item.goods_name }}</p>
                          <div class="seckill-price-wrap">
                            <p class="ns-text-color">
                              ₵
                              <span>{{ item.seckill_price }}</span>
                            </p>
                            <p class="primary-price">₵{{ item.price }}</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </vue-seamless-scroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--订阅-->
      <div class="ns-bg-color ns-text-white" style="padding: 10px 0">
        <div class="content-div" >
          <div class="floor">
            <div flex="cross:center" style="line-height: 50px;">
              <i class="ec ec-newsletter" style="font-size: 25px;margin-right: 5px;"></i>
              <div flex-box="1" style="font-size: 16px;">Get coupons for first registration.</div>
              <div flex="">
                <input type="text" placeholder="Edit Address" class="email-ob-input" @click="$router.push('/register')">
                <div class="email-ob-btn" @click="$router.push('/register')">Sign Up</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 浮层区 -->
      <div class="floatLayer-wrap" v-if="floatLayer.is_show">
        <div class="floatLayer">
          <div class="img-wrap">
            <img :src="$img(floatLayer.img_url)" @click="$router.pushToTab(floatLayer.link.url, '_self')"/>
          </div>
          <i class="el-icon-circle-close" @click="closeFloat"></i>
        </div>
      </div>

      <!-- 悬浮搜索 -->
      <div class="fixed-box" :style="{ display: isShow ? 'block' : 'none' }" style="left:0">
        <div class="header-search">
          <el-row>
            <el-col :span="6">
              <router-link to="/" class="logo-wrap"><img :src="$img(siteInfo.logo)"/></router-link>
            </el-col>
            <el-col :span="13">
              <div class="in-sousuo">
                <div class="sousuo-box" style="display: flex;">
                  <input type="text" :placeholder="defaultSearchWords" v-model="keyword" @keyup.enter="search"
                         maxlength="50"/>
                  <el-button type="primary" size="small" @click="search">Search</el-button>
                </div>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="cart-wrap">
                <router-link class="cart" to="/cart">
                  <span>Cart</span>
                  <el-badge v-if="cartCount" :value="cartCount" type="primary"><i class="iconfont icongouwuche"></i>
                  </el-badge>
                  <i v-else class="iconfont icongouwuche"></i>
                </router-link>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import floorStyle1 from './components/floor-style-1.vue';
import floorStyle2 from './components/floor-style-2.vue';
import floorStyle3 from './components/floor-style-3.vue';
import floorStyle4 from './components/floor-style-4.vue';
import floorStyle5 from './components/floor-style-5.vue';
import index from './_index/index.js';

export default {
  name: 'index',
  components: {floorStyle1, floorStyle2, floorStyle3, floorStyle4, floorStyle5},
  mixins: [index]
};
</script>

<style lang="scss" scoped>
@import './_index/index.scss';
</style>

<style lang="scss">
.count-down {
  span {
    display: inline-block;
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    background: #383838;
    color: #ffffff;
    border-radius: 2px;
  }
}
.email-ob-btn,
.email-ob-input{
  border-radius: 25px 25px !important;
  height: 50px !important;
  border: 0 !important;
  line-height: 45px;
}
.email-ob-btn{
  padding: 0 35px 0 25px;
  background-color: #33483B;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  cursor: pointer;
}
.email-ob-input{
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: #FFFFFF !important;
  color: #000;
}
</style>
